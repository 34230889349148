
<template>
    <div class="userInfoBox">
        <van-nav-bar title="修改密码" safe-area-inset-top   placeholder fixed  left-text="返回" left-arrow  @click-left="$router.push('/user/userInfo')" /> 
        <div class="FormBox">
            <van-form  @submit="onSubmit" :show-error='false' ref="form">
                <van-cell-group>
                    <van-field :right-icon="passwoed1?'eye-o':'closed-eye'" @click-right-icon='passwoed1 = !passwoed1' label-width='60px' :type="passwoed1?'password':'text'" required v-model="FormData.OldPassWord" label="原密码" placeholder="原密码"  :rules="[{ required: true, message: '原密码' }]" />
                    <van-field label-width='60px' required v-model="FormData.vercode" label="验证码" :placeholder="'发送'+$route.query.phone+'验证码'"  :rules="[{ required: true, message: '验证码' }]" >
                        <template #button>
                            <van-button class="ButtomBox" size="small" type="warning" native-type='button' @click="GetYzm" :disabled="ButtomDisabled">{{ButtomTetx}}</van-button>
                        </template>
                    </van-field>
                    <van-field :right-icon="passwoed2?'eye-o':'closed-eye'" @click-right-icon='passwoed2 = !passwoed2' label-width='60px' :type="passwoed2?'password':'text'" required v-model="FormData.NewPassWord" label="新密码" placeholder="新密码"  :rules="[{ required: true, message: '新密码' },{ validator:validator, message: '密码过于简单(至少需要两种类型组合)' }]" />
                    <van-field :right-icon="passwoed3?'eye-o':'closed-eye'" @click-right-icon='passwoed3 = !passwoed3' label-width='60px' :type="passwoed3?'password':'text'" required v-model="AngenPassWord" label="确认密码" placeholder="确认密码"  :rules="[{ required: true, message: '确认密码' },{ validator:identical, message: '两次密码不一致' }]" />
                </van-cell-group>
            </van-form>
            <!-- <van-icon name="closed-eye" /> -->
            <!-- <van-icon name="eye-o" /> -->
        </div>
        <div class="InfButtom van-hairline--top">
            <van-button  type="primary" block @click="ClickUpdataPass">确认修改</van-button>
        </div>
    </div>
</template>
<script>
import { NavBar,Form,CellGroup,Field,Button ,Toast } from 'vant';
import { Api_System_SeedCode ,Api_YyUserInfo_UpdatePassWord} from "@/api/userInfo";
import { checkStrong } from "@/utils/validate";
export default {
    components:{
        [NavBar.name]: NavBar,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button,
        [Toast.name]: Toast,
    },
    data() {
        return {
            ButtomDisabled:false,
            ButtomTetx:'发送验证码',
            AngenPassWord:'',
            passwoed1:true,
            passwoed2:true,
            passwoed3:true,
            FormData:{
                phone:'',
                vercode:'',
                vercode_token:'',
                OldPassWord:'',
                NewPassWord:'',
            }
        }
    },
    methods:{
        identical(){
            if(this.FormData.NewPassWord === this.AngenPassWord) {
                return true
            }else {
                return false
            }
        },
        validator(){
            if(this.FormData.NewPassWord.length > 5){
                if( checkStrong(this.FormData.NewPassWord) < 2 ){
                    return false
                }else {
                    return true
                }
            }else {
                 return false
            }
        },
        GetYzm(){
            this.ButtomDisabled = true;
            if(this.$route.query.phone.length == 11){

                //  发送验证码 （'绑定手机号'）
                this.FormData.phone = this.$route.query.phone
                Api_System_SeedCode({
                    phone:this.$route.query.phone
                }).then(res=>{
                    this.FormData.vercode_token = res.vercode_token
                    var Time = 90;
                    var ClerTime = setInterval(() => {
                        this.ButtomTetx = Time + 's后发送'
                        Time--
                        if(Time <=0) {
                            this.ButtomTetx = '发送验证码'
                            this.ButtomDisabled= false
                            clearInterval(ClerTime)
                        }
                    }, 1000);

                }).catch(err=>{
                    this.ButtomDisabled = false
                })
            }else {
                this.$toast('发送失败')
                 this.ButtomDisabled = false
            }
        },
        onSubmit(){
            Api_YyUserInfo_UpdatePassWord(this.FormData).then(res=>{
                console.log(res)
                this.$toast('修改成功')
                localStorage.clear()
                this.$router.push('/login')
            }).catch(err=>{
                this.$toast(err.msg)
                console.log(err)
            })
        },
        ClickUpdataPass(){
            this.$refs.form.submit()
        }
    }
}
</script>
<style lang="scss" scoped>
.userInfoBox {
    display: flex;
    flex-direction: column;
    // padding: 10px 14px;
    // background-color: #fff;
    height: 100vh;
    
    .FormBox {
        flex: 1;
        padding-top: 10px;
        overflow-y: auto;
        /deep/ .van-field__button {
            overflow: hidden;
            display: flex;
        }
        .ButtomBox {
            height: 24px;
            line-height: 24px;
        }
    }
    .InfButtom {
        padding: 10px 14px;
        background-color: #fff;

        .van-button {
            line-height: 38px;
            height: 38px;
        }
    }
}
</style>